/**
 * Created by charnjeetelectrovese@gmail.com on 5/1/2020.
 */

import {formDataRequest, postRequest} from "../libs/AxiosService.util";

export async function serviceGetAppSettings(params) {
    return await postRequest('app/settings', params);
}

export async function serviceUpdateGeoFence(params) {
    return await postRequest('app/settings/geofence/update', params);
}

export async function serviceUpdatePolicies(params) {
    return await postRequest('app/settings/update/policies', params);
}

export async function serviceUploadEmployeeInduction(params) {
    return await formDataRequest('app/settings/upload/induction', params);
}
export async function serviceChangeEmployeeCAGR(params) {
    return await postRequest('app/settings/update/cagr', params);
}

export async function servicePmsUpdateIncrements(params){
    return await postRequest('pms/update/increments', params);
}

export async function serviceGetPmsIncrements(params){
    return await postRequest('pms/increments', params);
}
export async function serviceSetEmailTrigger(params) {
    return await postRequest('employees/send/bulk/email', params);
}
export async function serviceGetUscList(params) {
    return await postRequest('location/salary', params);
}
export async function serviceGetUscScriptUpdate(params) {
    return await postRequest('location/salary/update/salary', params);
}
export async function serviceGetUscDetails(params) {
    return await postRequest('location/salary/detail', params);
}
export async function serviceGetUscUpdate(params) {
    return await postRequest('location/salary/update', params);
}
export async function serviceGetCurrencyList(params) {
    return await postRequest('currencies', params);
}
export async function serviceGetCurrencyDetails(params) {
    return await postRequest('currencies/detail', params);
}
export async function serviceGetCurrencyUpdate(params) {
    return await postRequest('currencies/update', params);
}