import React from "react";
import styles from "./Style.module.css";
const HREscalationMatrixView = () => {
  return (
    <div className={styles.container}>
      <div className={styles.logoImg}>
        <img
          src={require("../../assets/Synthimed_Assets/ic_logo.jpg")}
       
          height={80}
          alt=""
        />
      </div>
      <div className={styles.loginSignupText}>
        <h1 className={styles.headingText}>HR Escalation Matrix</h1>
        <div className={styles.newLine} />
      </div>
<div className={styles.tableContainer}>

      <table className={styles.myTable}>
        <thead>
          <tr className={styles.centerText}>
            <th colSpan="4">ESCALATION LEVEL I/III</th>
          </tr>
        </thead>

        <tbody>
          <tr className={styles.tableSubHeading}>
            <td>If you are joining at</td>
            <td>Name</td>
            <td>Contact</td>
            <td>Email</td>
          </tr>
          <tr className={styles.bgColorRow1}>
            <td>SLPL Corporate Office </td>
            <td>Kartikey Verma</td>
            <td>8219879046</td>
            <td>hr1.derabassi@synthimed.com</td>
          </tr>
          <tr className={styles.grayRow}>
            <td>SLPL Derabassi (API)</td>
            <td>Mohit</td>
            <td>8968374200</td>
            <td>hr.jobs@synthimed.com</td>
          </tr>
          <tr className={styles.bgColorRow1}>
            <td>SLPL Samba (API)</td>
            <td>Shubham Bharti</td>
            <td>9878321442</td>
            <td>corporate.hr@synthimed.com</td>
          </tr>
          <tr className={styles.grayRow}>
            <td>R&D Mohali </td>
            <td>Anupam Sharma</td>
            <td>9878829553</td>
            <td>anupam.sharma@synthimed.com</td>
          </tr>
          <tr className={styles.bgColorRow1}>
            <td>SLPL Focal Point (API) </td>
            <td>Davinder Pal</td>
            <td>8146183255</td>
            <td>essix.hr@synthimed.com</td>
          </tr>
        </tbody>
      </table>
      <br />
     
      <table className={styles.myTable}>
        <thead>
          <tr className={styles.centerText}>
            <th colSpan="4">ESCALATION LEVEL II/III</th>
          </tr>
        </thead>

        <tbody>
          <tr className={styles.tableSubHeading}>
            <td>If you are joining at</td>
            <td>Name</td>
            <td>Contact</td>
            <td>Email</td>
          </tr>
          <tr className={styles.bgColorRow1}>
            <td>Corporate - HR </td>
            <td>Gagandeep Singh</td>
            <td>9872870540</td>
            <td>Gagandeep.singh@synthimed.com</td>
          </tr>
        </tbody>
      </table>
      <br />
   
      <table className={styles.myTable}>
        <thead>
          <tr className={styles.centerText}>
            <th colSpan="4">ESCALATION LEVEL III/III</th>
          </tr>
        </thead>

        <tbody>
          <tr className={styles.tableSubHeading}>
            <td>If you are joining at</td>
            <td>Name</td>
            <td>Contact</td>
            <td>Email</td>
          </tr>
          <tr className={styles.bgColorRow1}>
            <td>HEAD Corporate - HR </td>
            <td>Manpreet Singh Sodhi</td>
            <td>9876436211</td>
            <td>Manpreet.singh@synthimed.com</td>
          </tr>
        </tbody>
      </table>
</div>
    </div>
  );
};

export default HREscalationMatrixView;
